/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */


@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;400;500;600&display=swap');

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* styles.css */

:root{
  --ion-font-family: 'Nunito', sans-serif;
}

// body.dark-mode {
//   background-color: #2d3436;
//   color: #dfe6e9;
// }

// body.light-mode {
//   background-color: #dfe6e9;
//   color: #2d3436;
// }

// ion-button {
//   text-transform: none;
// }

.mt-3{
  margin-top: 3px;
}

.mt-5{
  margin-top: 5px;
}

.mt-15{
  margin-top: 15px;
}

.mb-3{
  margin-bottom: 3px;
}

.mb-5{
  margin-bottom: 5px;
}

.mb-15{
  margin-bottom: 15px;
}

.ph-5{
  padding: 0 5% 0 5%;
}

.ion-color-doeet {
  --ion-color-base: var(--ion-color-doeet);
  --ion-color-base-rgb: var(--ion-color-doeet-rgb);
  --ion-color-contrast: var(--ion-color-doeet-contrast);
  --ion-color-contrast-rgb: var(--ion-color-doeet-contrast-rgb);
  --ion-color-shade: var(--ion-color-doeet-shade);
  --ion-color-tint: var(--ion-color-doeet-tint);
}

.loading {
  background-color: #e5e5e5;
}
